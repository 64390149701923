import React from 'react'
import { Link } from 'gatsby'
import styles from './test.module.scss'
import button from '../../buttons.module.scss'
import EmptyBlock from '../../Header/emptyBlock'

import logoTracker from '../../img/icons/integrations/Timetrackers/Logo_tracker_github.svg'
import logoTracker1 from '../../img/icons/integrations/Timetrackers/Logo_tracker_trello_2.svg'
import logoTracker2 from '../../img/icons/integrations/Timetrackers/Logo_tracker_trello_3.svg'
import logoTracker3 from '../../img/icons/integrations/Timetrackers/Logo_tracker_trello_4.svg'
import logoTracker4 from '../../img/icons/integrations/Timetrackers/ClickUp.png'
import logoTracker5 from '../../img/icons/integrations/Timetrackers/Logo_tracker_trello_5.svg'
import arrow from '../../img/icons/Icon_indicator.svg'

const FirstBlock = () => {

  const openSignUp = () => {
    return window.location.href = '/sign-up/';
  }

  return (
    <>
      <EmptyBlock />
      <div className={styles.body}>
        <div className={styles.mainWrap}>
          <div id='height-fix' className={styles.main}>
            <div className={styles.left}>
              <h1>Connect Time Tracker With <span>Your Favorite</span> Project Management Tools</h1>
              <p>Task Management Apps Are Getting Better With DueFocus</p>
              <div className={styles.buttons}>
                <button
                  onClick={openSignUp}
                  className={`${button.buttonSignUpBig} ${styles.visibleButton}`}
                >
                  Start Free Trial
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.timeTrackers}>
          <div>
            <h2>Task Management Apps Are Getting Better With DueFocus</h2>
            <div className={styles.block}>
              <Link to='/time-tracking-jira/'>
                <div>
                  <img src={logoTracker3} width="auto" height="auto" alt="Jira" />
                  <span className={styles.blockTitle}>Jira</span>
                  <p>Track time with the best software development tool for agile teams</p>
                  <div><span>Learn more</span><img src={arrow} width="auto" height="auto" alt="img" /></div>
                </div>
              </Link>
              <Link to='/time-tracking-trello/'>
                <div>
                  <img src={logoTracker1} width="auto" height="auto" alt="Trello" />
                  <span className={styles.blockTitle}>Trello</span>
                  <p>Manage your time in Trello with DueFocus time tracking software</p>
                  <div><span>Learn more</span><img src={arrow} width="auto" height="auto" alt="img" /></div>
                </div>
              </Link>
              <Link to='/time-tracking-asana/'>
                <div>
                  <img src={logoTracker2} width="auto" height="auto" alt="Asana" />
                  <span className={styles.blockTitle}>Asana</span>
                  <p>With DueFocus you can track your teams time directly in Asana </p>
                  <div><span>Learn more</span><img src={arrow} width="auto" height="auto" alt="img" /></div>
                </div>
              </Link>
            </div>
            <div className={styles.block}>
              <Link to='/time-tracking-asana/'>
                <div>
                  <img src={logoTracker2} width="auto" height="auto" alt="Asana" />
                  <span className={styles.blockTitle}>Asana</span>
                  <p>Track your working hours by syncing Asana and DueFocus</p>
                  <div><span>Learn more</span><img src={arrow} width="auto" height="auto" alt="img" /></div>
                </div>
              </Link>
              <Link to='/time-tracking-github/'>
                <div>
                  <img src={logoTracker} width="auto" height="auto" alt="Github" />
                  <span className={styles.blockTitle}>Github</span>
                  <p>Control time you spend on developing with DueFocus time tracking app</p>
                  <div><span>Learn more</span><img src={arrow} width="auto" height="auto" alt="img" /></div>
                </div>
              </Link>
            </div>
            <div className={styles.block}>
              <Link to='/time-tracking-github/'>
                <div>
                  <img src={logoTracker} width="auto" height="auto" alt="Github" />
                  <span className={styles.blockTitle}>Github</span>
                  <p>Control time you spend on developing with DueFocus time tracking app</p>
                  <div><span>Learn more</span><img src={arrow} width="auto" height="auto" alt="img" /></div>
                </div>
              </Link>
              <Link to='/time-tracking-clickup/'>
                <div>
                  <img src={logoTracker4} width="auto" height="auto" alt="ClickUp" />
                  <span className={styles.blockTitle}>ClickUp</span>
                  <p>Sync DueFocus and ClickUp and get the things done in the best way</p>
                  <div><span>Learn more</span><img src={arrow} width="auto" height="auto" alt="img" /></div>
                </div>
              </Link>
              <Link to='/time-tracking-gitlab/'>
                <div>
                  <img src={logoTracker5} width="auto" height="auto" alt="GitLab" />
                  <span className={styles.blockTitle}>GitLab</span>
                  <p>Project management is transparent with DueFocus time tracker</p>
                  <div><span>Learn more</span><img src={arrow} width="auto" height="auto" alt="img" /></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(FirstBlock); 