import React from 'react'
import style from './index.module.scss'
import button from '../../buttons.module.scss'

// import iconForm from '../../img/icons/ic_drag.svg'
export default class FormBlock extends React.Component {
  state = {
    email: '',
    emailValid: false,
    nameValid: false,
    messageValid: false
  }

  showModal = () => {
    const modal = document.getElementById('modal-sign-up');
    if (modal.className === 'show-modal') {
      modal.removeAttribute('class');
    } else {
      modal.className = 'show-modal';
      document.body.className = 'modal-active';
    }
    return false;
  }

  isFormValid = () => {
    const blackList = [
      "@sharklasers.com",
      "@guerrillamail.info",
      "@grr.la",
      "@guerrillamail.biz",
      "@guerrillamail.com",
      "@guerrillamail.de",
      "@guerrillamail.net",
      "@guerrillamail.org",
      "@guerrillamailblock.com",
      "@pokemail.net",
      "@spam4.me",
      "@verifymail.win",
      "@owlymail.com",
      "@nwytg.net",
      "@next2cloud.info",
      "@alexbox.online",
      "@321-email.com",
      "@utooemail.com",
      "@22office.com",
      "@heximail.com",
      "@4senditnow.com",
      "@braun4email.com",
      "@onecitymail.com",
      "@utoo.email",
      "@ezehe.com",
      "@armyspy.com",
      "@sharklasers.com",
      "@guerrillamail.info",
      "@grr.la",
      "@guerrillamailblock.com",
      "@pokemail.net",
      "@spam4.me",
      "@verifymail.win",
      "@owlymail.com",
      "@nwytg.net",
      "@next2cloud.info",
      "@alexbox.online",
      "@321-email.com",
      "@utooemail.com",
      "@22office.com",
      "@heximail.com",
      "@4senditnow.com",
      "@braun4email.com",
      "@onecitymail.com",
      "@utoo.email",
      "@ezehe.com",
      "@armyspy.com",
      "@sharklasers.com",
      "@guerrillamail.info",
      "@grr.la",
      "@guerrillamailblock.com",
      "@pokemail.net",
      "@spam4.me",
      "@verifymail.win",
      "@owlymail.com",
      "@nwytg.net",
      "@next2cloud.info",
      "@alexbox.online",
      "@321-email.com",
      "@utooemail.com",
      "@22office.com",
      "@heximail.com",
      "@4senditnow.com",
      "@braun4email.com",
      "@onecitymail.com",
      "@utoo.email",
      "@ezehe.com",
      "@armyspy.com",
      "@cuvox.de",
      "@dayrep.com",
      "@einrot.com",
      "@fleckens.hu",
      "@gustr.com",
      "@jourrapide.com",
      "@rhyta.com",
      "@superrito.com",
      "@teleworm.us",
      "@figurescoin.com",
      "@besttempmail.com",
      "@iffymedia.com",
      "@payspun.com",
      "@beluckygame.com",
      "@thrubay.com",
      "@claimab.com",
      "@bestsoundeffects.com",
      "@profast.top",
      "@icemail.club",
      "@vradportal.com",
      "@trash-mail.com",
      "@you-spam.com",
      "@re-gister.com",
      "@fake-box.com",
      "@trash-me.com",
      "@opentrash.com",
      "@lazyinbox.us",
      "@tempr.email",
      "@discard.email",
      "@discardmail.com",
      "@discardmail.de",
      "@spambog.com",
      "@spambog.de",
      "@spambog.ru",
      "@0815.ru",
      "@hulapla.de",
      "@sweetxxx.de",
      "@btcmail.pw",
      "@knol-power.nl",
      "@hartbot.de",
      "@freundin.ru",
      "@smashmail.de",
      "@s0ny.net",
      "@wikidocuslava.ru",
      "@jumonji.tk",
      "@i6.cloudns.cc",
      "@i6.cloudns.cx",
      "@posta.store",
      "@milk.gage.ga",
      "@smap.4nmv.ru",
      "@spam.2012-2016.ru",
      "@pecinan.net",
      "@budaya-tionghoa.com",
      "@starpower.space",
      "@yevme.com",
      "@harakirimail.com",
      "@nezzart.com",
      "@hezll.com",
      "@thunderbolt.science",
      "@lajoska.pe.hu",
      "@hotmailproduct.com",
      "@yahooproduct.net",
      "@attnetwork.com",
      "@1mail.x24hr.com",
      "@from.onmypc.info",
      "@pw.epac.to",
      "@now.mefound.com",
      "@mowgli.jungleheart.com",
      "@2.tebwinsoi.ooo",
      "@2.sexymail.ooo",
      "@staticintime.de",
      "@jancok.in",
      "@bangsat.in",
      "@kontol.co.uk",
      "@kontol.city",
      "@barrabravaz.com",
      "@myfreemail.space",
      "@gabox.store",
      "@2.safemail.cf",
      "@2.safemail.tk",
      "@m0.guardmail.cf",
      "@m1.guardmail.cf",
      "@m2.guardmail.cf",
      "@yourspamgoesto.space",
      "@m.u-torrent.ga",
      "@m.u-torrent.gq",
      "@m.u-torrent.cf",
      "@De.NewHorizons.gq",
      "@1.TemprEmail.tk",
      "@1.TemprEmail.cf",
      "@A.TemprEmail.tk",
      "@A.TemprEmail.cf",
      "@pfui.ru",
      "@0815.su",
      "@pecinan.com",
      "@pecinan.org",
      "@budayationghoa.com",
      "@Noways.ddns.net",
      "@pw.my3mail.tk",
      "@pw.my3mail.cf",
      "@pw.my3mail.ml",
      "@pw.my3mail.ga",
      "@pw.my3mail.gq",
      "@a.e656.tk",
      "@a.e656.cf",
      "@a.e656.ml",
      "@a.e656.ga",
      "@a.e656.gq",
      "@b.e656.tk",
      "@b.e656.ml",
      "@b.e656.cf",
      "@b.e656.ga",
      "@b.e656.gq",
      "@iminimalm.com",
      "@guerrillamail.com",
      "@mailinator.com",
      "@yopmail.com",
      "@owlymail.com",
      "@dispostable.com",
      "@10minutemail.com",
      "@temp-mail.org",
      "@tempail.com",
      "@mailnesia.com",
      "@fakemailgenerator.com",
      "@fakemailgenerator.net",
      "@emailfake.com",
      "@email-fake.com",
      "@trash-mail.com",
      "@mailsac.com",
      "@lazyinbox.com",
      "@tempr.email",
      "@haribu.net",
      "@harakirimail.com",
      "@mytemp.emai",
      "@emailondeck.com",
      "@example.com",
    ];
    const name = document.getElementById('name');
    const message = document.getElementById('message');
    const email = document.getElementById('email-feedback');
    const pattern = /^([a-z0-9_.!#$%^&*-+()])+@[a-z0-9.-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i;

    if (blackList.some(part => email.value.includes(part)) || email.value === '' || !pattern.test(email.value)) {
      this.setState({ emailValid: false });
      email.className = 'show-error-email';
      document.getElementById('email-error').className += ' show-message'
    } else {
      this.setState({ emailValid: true });
      email.removeAttribute('class');
      document.getElementById('email-error').classList.remove('show-message')
    }
    if (email.value === '') {
      document.getElementById('email-error').classList.remove('show-message')
    }
    if (message.value.length < 10) {
      this.setState({ messageValid: false });
      message.className = 'show-error-email';
    } else {
      this.setState({ messageValid: true });
      message.removeAttribute('class');
    }
    if (name.value.length < 2) {
      this.setState({ nameValid: false });
      name.className = 'show-error-email';
    } else {
      this.setState({ nameValid: true });
      name.removeAttribute('class');
    }
    return false;
  }
  submitForm = (event) => {
    event.preventDefault();
    const name = document.getElementById('name');
    const message = document.getElementById('message');
    const email = document.getElementById('email-feedback');
    if (this.state.emailValid === true && this.state.messageValid === true && this.state.nameValid === true) {
      window.Intercom("update", { email: email.value, name: name.value });
      window.Intercom('showNewMessage', message.value);
      name.value = '';
      message.value = '';
      email.value = '';
    }
  }
  render() {
    return (
      <>
        <div className={style.formBlock}>
          <h2 className={style.formBlockTitle}>{this.props.mainTitle}</h2>
          <form>
            <div>
              <div className={style.inputs}>
                <div className={style.inputBlock}>
                  <span>Name</span>
                  <input onChange={this.isFormValid} maxLength='78' id='name' placeholder='Alex' name='name' type='name' />
                </div>
                <div className={style.inputBlock}>
                  <span>E-mail</span>
                  <input onChange={this.isFormValid} maxLength='78' id='email-feedback' placeholder='name@email.com' type='email' />
                  <span className={style.errors} id='email-error'>It doesn't looks like an e-mail</span>
                </div>
              </div>
              <div className={style.textArea}>
                <span>Your Message</span>
                <textarea onChange={this.isFormValid} id='message' placeholder='Your Message' name="textArea" cols="80" rows="7"></textarea>
              </div>
            </div>
            <button
              onClick={this.submitForm}
              type='submit'
              className={button.buttonSignUp}
            >
              Send Message
            </button>
          </form>
        </div>
      </>
    )
  }
}
