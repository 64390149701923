import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import Footer from '../components/Footer/footer'
import Integration from '../components/Integrations/index'
import { Helmet } from "react-helmet";

import logo from '../components/img/integrations.png'

// const content = `Seamless Integration for tracking time with popular task management tools such as Jira, Trello, Asana, GitLab, GitHub, ClickUp, etc.`
const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const Integrations = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>Integrations for Tracking Time for Free with Project Management Tools</title>
      <meta name="description" content={content} />
      <meta property="og:image" content={logo} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Integrations for Tracking Time for Free with Project Management Tools" />
      <meta property="og:description" content={content} />
      <meta property="og:url" content="https://www.duefocus.com/" />
      <meta name="twitter:image:src" content={logo} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.duefocus.com/integrations/" />
    </Helmet>
    <Header />
    <Integration />
    <Footer />
  </>
)

export default Integrations