
import React from "react";
import FirstBlock from '../Integrations/FirstBlock/index_test'
import SecondBlock from '../Integrations/SecondBlock/index_test'
// import ThirdBlock from '../Integrations/ThirdBlock/index'
import FormBlock from '../Integrations/FormBlock/index'
import LastBlock from '../LastBlock/Footer'

// import GetStarted from '../Integrations/FormBlock/getStarted'
// import logoBottom from '../img/icons/Logo_icon_duefocus.svg'

const mainTitle = 'Have any Questions?'

const Integration = () => {
return (
 <>
   <FirstBlock />
   <SecondBlock />
   {/* <ThirdBlock /> */}
   <FormBlock mainTitle={mainTitle} />
   <LastBlock />
   {/* <GetStarted logo={logoBottom}/> */}
 </>
  )
}

export default React.memo(Integration);
