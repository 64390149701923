import React from 'react'
import style from './test.module.scss'
import button from '../../buttons.module.scss'
import { Link } from "gatsby";

import iconPlus from '../../img/icons/Icon_plus_2.svg'

import integration3 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_bitbucket.svg'
import integration4 from '../../img/icons/integPageIcons/allIntegrations/breeze_logo_icons.png'
import integration6 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_google-calendar.svg'
import integration8 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_insighty.svg'
import integration10 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_liquidplanner.svg'
import integration11 from '../../img/icons/integPageIcons/allIntegrations/MantisHub.svg'
import integration12 from '../../img/icons/integPageIcons/allIntegrations/Odoo_logo_rgb.svg'
import integration13 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_pivotal.svg'
import integration14 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_redbooth.svg'
import integration15 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_redmine.svg'
import integration16 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_temworks.svg'
import integration17 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_todoist.svg'
import integration18 from '../../img/icons/integPageIcons/allIntegrations/ActiveCollab.svg'
import integration20 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_wrike.svg'
import integration21 from '../../img/icons/integPageIcons/allIntegrations/wunderlist.svg'
import integration22 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_youtrack.svg'
import integration23 from '../../img/icons/integPageIcons/allIntegrations/rally.svg'
import integration24 from '../../img/icons/integPageIcons/allIntegrations/ZOHO.svg'
import integration25 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_microsoft.svg'
import integration26 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_outlook.svg'
import integration29 from '../../img/icons/integPageIcons/allIntegrations/azure-1.svg'
import integration30 from '../../img/icons/integPageIcons/allIntegrations/Logo_tracker_podio.svg'


export default class SecondBlock extends React.Component {

  state = {
    hideAllIntegrations: true
  }

  handleShowMore = () => {
    this.setState({ hideAllIntegrations: !this.state.hideAllIntegrations });
    if (this.state.hideAllIntegrations === true) {
      document.getElementById('line1').className += ' disable-integrations'
      document.getElementById('line2').className += ' disable-integrations'
    } else {
      document.getElementById('line1').classList.remove('disable-integrations')
      document.getElementById('line2').classList.remove('disable-integrations')
    }
  }


  render() {

    return (
      <>
        <div className={style.secondBlock}>
          <h2>DueFocus Integrations</h2>
          <div className={style.allIntegrations}>
            <div id='line1' className={style.line}>
              <div>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration3} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Bitbucket</span>
                    <span>Use DueFocus to track time and estimate issues</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration29} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Visual Studio</span>
                    <span>Optimize workflow with DueFocus</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration17} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Todoist</span>
                    <span>Use DueFocus to track time on your to-do's </span>
                  </div>
                </Link>
              </div>
            </div>
            <div id='line2' className={style.line}>
              <div>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration21} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Wunderlist</span>
                    <span>Use just one click to track time from Wunderlist</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration20} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Wrike</span>
                    <span>Improve your projects profitability with DueFocus</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration16} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Teamwork</span>
                    <span>Track time spent on your tasks with DueFocus</span>
                  </div>
                </Link>
              </div>
            </div>
            <div id='line2' className={style.line}>
              <div>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration30} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Podio</span>
                    <span>Get the best from Podio with time tracking tool</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration12} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Odoo</span>
                    <span>Track time you spent on project with DueFocus</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration23} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Rally</span>
                    <span>Sync DueFocus to complete tasks on time</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className={style.line}>
              <div>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration13} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Pivotal</span>
                    <span>Connect DueFocus to track time from Pivotal stories</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration8} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Insightly</span>
                    <span>Deliver projects even faster with time tracking app</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration25} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Microsoft Todo</span>
                    <span>Get your stuff done faster by syncing DueFocus</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className={style.line}>
              <div>
                <Link to='/time-tracking-redbooth/' className={style.blockIntegration}>
                  <img src={integration14} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Redbooth</span>
                    <span>Organize team effectively with our time tracker</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration15} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Redmine</span>
                    <span>Optimize workflow with time tracking app</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration24} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Zoho</span>
                    <span>Track time to your Zoho tasks</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className={style.line}>
              <div>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration18} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Activecollab</span>
                    <span>Project management is easier with DueFocus</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration10} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Iiquidplanner</span>
                    <span>Workflow is transparent with DueFocus</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration4} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Breeze</span>
                    <span>Task estimation is easier with time tracking app</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className={style.line}>
              <div>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration22} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>YouTrack</span>
                    <span>Track time with DueFocus Google Chrome Extension</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration11} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>MantisHub</span>
                    <span>Track bugs faster with time tracking software</span>
                  </div>
                </Link>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration26} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Outlook</span>
                    <span>Manage your tasks easily with our time tracking app</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className={style.line}>
              <div>
                <Link to='/time-tracker-integrations/' className={style.blockIntegration}>
                  <img src={integration6} width="auto" height="auto" alt='integration' />
                  <div>
                    <span className={style.blockIntegrationTitle}>Google-calendar</span>
                    <span>Track time directly from events in Google Calendar</span>
                  </div>
                </Link>
                <a target='_blank' rel="noopener noreferrer" href='https://duefocus.canny.io/' className={`${style.blockIntegration} ${style.lastBlock}`}>
                  <img src={iconPlus} width="auto" height="auto" alt='iconPlus' />
                  <p>Request New Integration</p>
                </a>
                <div className={style.hideblock}>
                </div>
              </div>
            </div>
            <div className={style.line}>
              <button
                className={`${style.showMore} ${button.learnMore}`}
                onClick={this.handleShowMore}
              >
                {
                  this.state.hideAllIntegrations === true
                    ? 'Show More'
                    : 'Show Less'
                }
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
}
